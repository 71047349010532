
import { config } from '@/conf'
import { country_iso2, languages } from '@/utils/language'

export default {
  name: 'LangSelectModal',
  setup () {
    const flag = (l: string) => {
      // l = l === 'en' ? 'gb' : l === 'cs' ? 'cz' : l
      return `https://hatscripts.github.io/circle-flags/flags/${country_iso2[l]}.svg`
    }

    return {
      config, languages, flag
    }
  }
}
