const contact = require("./contact.json");
const cookies = require("./cookie-policy.json");
const core = require("./core.json");
const faq = require("./faq.json");
const footer = require("./footer.json");
const home = require("./home.json");
const login = require("./login.json");
const pricing = require("./pricing.json");
const privacy = require("./privacy-policy.json");
const register = require("./register.json");
const reset = require("./reset-pass.json");
const terms = require("./terms-of-use.json");
const titles = require("./titles.json");
const unsub = require("./unsub.json");
const user = require("./user-info.json");
const faqs = require("./faqs.json");

const strings = {
  contact: contact,
  cookies: cookies,
  core: core,
  faq: faq,
  footer: footer,
  home: home,
  login: login,
  pricing: pricing,
  privacy: privacy,
  register: register,
  reset: reset,
  terms: terms,
  titles: titles,
  unsub: unsub,
  user: user,
  faqs: faqs
};

export { strings };
