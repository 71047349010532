import { session } from '@/utils/localstorage'
import { Response, XHR } from '@/utils/xhr'

class Locator {
  __xhr     : XHR
  locate    : ParsedPhoneNumber|undefined
  notify    : ParsedPhoneNumber|undefined
  message   : string|undefined
  submit    : (()=>void)|undefined
  on_change : (()=>void)|undefined

  constructor () {
    this.__xhr = new XHR()
  }

  init (
    locate: ParsedPhoneNumber|undefined = undefined,
    notify: ParsedPhoneNumber|undefined = undefined,
    message: string|undefined = undefined
  ) {

    // at least locate number is set
    if (locate) {
      this.locate  = locate
      this.notify  = notify
      this.message = message
      // save to session storage
      session.set('locator', this)
      return
    }

    // attempt to get stored values from session storage
    let stored = session.get('locator')
    if (!stored) return
    // parse json
    stored = JSON.parse(stored) as Locator
    // set props
    this.locate  = stored.locate
    this.notify  = stored.notify
    this.message = stored.message
  }

  // updates the 'notify' number and text message
  update (notify  : ParsedPhoneNumber, message: string) {
    this.notify  = notify
    this.message = message
    // save to session storage
    session.set('locator', this)
  }

  // submits location request
  request (): Promise<any> {
    return new Promise((resolve, reject) => {
      const l = this.locate?.dial + this.locate?.number!
      const n = this.notify?.dial + this.notify?.number!
      this.__xhr.get('/locate?l='+l+'&n='+n+'&m='+this.message).then((r: Response) => {
        if (this.on_change) this.on_change()
        resolve(r.data)
      }).catch((e: number) => {
        reject(e)
      })
    })
  }

  fetch (): Promise<Locations> {
    return new Promise((resolve, reject) => {

      this.__xhr.get('/locations').then((r: Response) => {
        const locations: Locations = []
        // decode response data
        const resp_data = r.decode<Locations>()
        if (!resp_data) resolve([])

        // @ts-ignore
        for (const l of resp_data!) { try { l.geo = JSON.parse(l.geo) }catch(e){} locations.push(l) }

        resolve(locations)
      }).catch((e: number) => {
        reject(e)
      })

    })
  }
}

interface ParsedPhoneNumber {
  dial:      string
  number:    string
  formatted: string
  country:   string
}

type Locations = Location[]

interface Location {
  id     : string
  number : string
  geo    : GeoData
  time   : number
  status : number
}

interface GeoData {
  lat       : number
  long      : number
  formatted : string
  number    : string
  street    : string
  suburb    : string
  city      : string
  admin     : string
  region    : string
  country   : string
  zip       : string
  time      : number
}

let locator = new Locator()

export { Locations, Location, GeoData, locator, ParsedPhoneNumber }
