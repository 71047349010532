<template>
  <footer class="app-footer">
    <div class="container">

      <!-- Top -->
      <div class="row">
        <div class="col-md-4">

          <!-- Logo -->
          <router-link to="/" class="footer-logo mb-1">
            <img src="./../../assets/logo.svg" alt="Finda" />
          </router-link>

          <p class="lead mt-2">
            {{ strings.core['Description'] }}
          </p>
        </div>

        <!-- Navs -->
        <div class="nav">
          <div>
            <label>{{ strings.core["Pages"] }}</label>
            <router-link to="/#how-it-works" class="link">{{ strings.core["How It Works"] }}</router-link>
            <router-link to="/pricing" slot="nav" class="link">{{ strings.core["Pricing"] }}</router-link>
            <router-link to="/faq" class="link">{{ strings.core["F.A.Q."] }}</router-link>
            <router-link to="/contact" class="link">{{ strings.core["Contact"] }}</router-link>
          </div>
          <div>
            <label>{{ strings.core["Account"] }}</label>
            <router-link to="/" class="link">{{ strings.core["Dashboard"] }}</router-link>
            <router-link to="/login" class="link">{{ strings.core["Login"] }}</router-link>
            <router-link to="/sign-up" class="link">{{ strings.core["Register"] }}</router-link>
            <router-link to="/cc" class="link">{{ strings.core["Unsubscribe"] }}</router-link>
          </div>
        </div>

        <!--
        <div class="col-lg-3">
          <label>&nbsp;</label>

          <router-link to="/cookie-policy" class="link">{{ strings.core["Cookies Policy"] }}</router-link>
          <router-link to="/privacy-policy" class="link">{{ strings.core["Privacy Policy"] }}</router-link>
          <router-link to="/terms" class="link">{{ strings.core["Terms and Conditions"] }}</router-link>


          <label>We accept all cards</label>
          <div style="display: flex;">
            <icon icon="visa" style="flex: 0 0 40px; height: 40px"/>
            <icon icon="mastercard" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
            <icon icon="maestro" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
            <icon icon="amex" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
            <icon icon="discover" style="flex: 0 0 40px; height: 40px" class="ml-05"/>
          </div>

          <div class="mt-2">
            <label>Language</label>
            <div class="lang-select">
              <a class="btn bg-white" @click="">
                {{  }}
              </a>
            </div>
          </div>


        </div>    -->

      </div>

      <!-- Bottom -->
      <div class="row mt-4">
        <div class="col-md-6">
          <p>&copy; Finda - {{ year }}</p>
        </div>
        <div class="col-md-6">
          <div class="footer-legal">
            <router-link to="/cookie-policy" class="link">{{ strings.core["Cookies Policy"] }}</router-link>
            <router-link to="/privacy-policy" class="link mt-0 ml-1">{{ strings.core["Privacy Policy"] }}</router-link>
            <router-link to="/terms" class="link mt-0 ml-1">{{ strings.core["Terms and Conditions"] }}</router-link>
          </div>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
import { strings } from '@/strings'
import Icon from '@/components/icons/icon'

export default {
  name: 'AppFooter',
  components: {Icon },
  setup () {
    const year = new Date().getFullYear()

    return {
      strings,
      year
    }
  }
}
</script>
