class WebStore {
  store: Storage

  constructor (store: Storage) {
    this.store = store
  }

  // web storage setter
  set (key: string, val: any) {
    // is object ? json encode object
    if (typeof val === 'object') {
      val = JSON.stringify(val)
    }
    // store
    this.store.setItem(key, val)
  }

  // web storage getter
  get (key: string) : any|undefined {
    // get
    return  this.store.getItem(key)
  }

  destroy () {
    this.store.clear()
  }

  delete (key: string) {
    this.store.removeItem(key)
  }
}

const session = new WebStore(sessionStorage)
const localstore = new WebStore(localStorage)

export { session, localstore }
