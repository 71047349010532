
import { strings } from '@/strings'
import { trial_price, subscription_price } from '@/utils/checkout'
import CurrencySelect from '@/components/ui/currency-select.vue'
import Icon from '@/components/icons/icon.vue'

export default {
  name: 'PricingPlans',
  components: { Icon, CurrencySelect },
  setup () {

    const set_price = (txt: string) => {
      return txt.replace('€19.50', subscription_price())
    }

    return { trial_price, subscription_price, strings, set_price }
  }
}
