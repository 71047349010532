
import { countries } from '@/utils/countries'
import DialcodeFlag from '@/components/ui/disalcode-flag.vue'
import { get_geo, user_geo } from '@/utils/geo'

export default {
  name: 'DailcodeSelect',
  components: { DialcodeFlag },
  props: {
    value: undefined
  },
  data () {
    return {
      selected: {
        name: 'Italy',
        dial_code: '+39',
        code: 'IT'
      },
      active: false,
      mounted: false
    }
  },
  methods: {
    on_select (country: string) {
      this.selected = country
      this.$emit('change', this.selected)
    },
    toggle () {
      this.active = !this.active
    },
    hide () {
      if (this.active) this.active = false
    },
    set (iso: string) {
      this.selected = countries[iso]
      this.$emit('change', this.selected)
    }
  },
  setup () {
    return {
      countries
    }
  },
  mounted () {
    if (this.value) {
      this.selected = countries[this.value]
      this.$emit('change', this.selected)
    } else {

      if (user_geo) {
        this.selected = countries[user_geo]
        this.$emit('change', this.selected)
      } else {
        const iso2 = get_geo() ?? 'ES'
        this.selected = countries[iso2]
        this.$emit('change', this.selected)
      }
    }

    setTimeout(() => {
      this.mounted = true
    }, 100)
  }
}
