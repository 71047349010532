import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { modal } from '@/components/main/modal'
import { config } from '@/conf'
import { gtag } from '@/utils/gtag'
import Home from './views/home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    // component: Home,
    component: Home,
    children: [
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import(/* webpackChunkName: "sign-up" */ './views/sign-up.vue')
      },
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ './views/login.vue')
      },
      {
        path: '/reset-pass',
        name: 'reset-pass',
        component: () => import(/* webpackChunkName: "reset-pass" */ './views/reset-pass.vue')
      }
    ]
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ './views/contact.vue')
  },
  {
    path: '/dash',
    name: 'dash',
    component: () => import(/* webpackChunkName: "dash" */ './views/dash.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ './views/faq.vue')
  },
  {
    path: '/l',
    name: 'locator',
    component: () => import(/* webpackChunkName: "locator" */ './views/locator.vue')
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import(/* webpackChunkName: "pricing" */ './views/pricing.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ './views/dash-settings.vue')
  },
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    alias: '/cc',
    component: () => import(/* webpackChunkName: "unsubscribe" */ './views/unsubscribe.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "legal" */ './components/legal/terms.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "legal" */ './components/legal/privacy.vue')
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: () => import(/* webpackChunkName: "legal" */ './components/legal/cookies.vue')
  },
  {
    path: '/sign-up-complete',
    name: 'sign-up-complete',
    component: () => import(/* webpackChunkName: "sign-up-complete" */ './views/sign-up-complete.vue')
  }
]

// set base url
const base = (config.lang.in_url ? '/' + config.lang.iso : '')

const router = createRouter({
  history: createWebHistory(base),
  routes
})

router.beforeEach((to, y, next) => {
  modal.destroy()
  document.body.setAttribute('view', to.name as string)
  next()
})

router.afterEach(() => {
  setTimeout(function () {
    window.scrollTo(0, 0)
  },200)
  // @ts-ignore
  gtag('js', new Date())
  // adwords
  // @ts-ignore
  // gtag('config', 'AW-381634419')
  // analytics
  // @ts-ignore
  gtag('config', 'G-GRB8KET09B')
})

export { router }
