import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["placeholder"]
const _hoisted_2 = {
  key: 1,
  class: "error bg-red"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dailcode_select = _resolveComponent("dailcode-select")!
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("form", {
    class: "tracker-input",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => ($setup.on_submit && $setup.on_submit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_dailcode_select, {
      ref: "dailcode-select",
      onChange: $setup.on_dialcode_change,
      value: $props.dial
    }, null, 8, ["onChange", "value"]),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.phone_input) = $event)),
      type: "tel",
      placeholder: $props.placeholder || $setup.strings.core['Phone'],
      autofocus: "",
      onFocus: _cache[1] || (_cache[1] = ($event: any) => ($setup.hide_error())),
      required: ""
    }, null, 40, _hoisted_1), [
      [_vModelText, $setup.phone_input]
    ]),
    (!$props.hide_btn)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass([$props.btn_color || 'bg-primary', "btn"]),
          type: "submit"
        }, [
          _createElementVNode("span", null, _toDisplayString($props.btn_text), 1),
          _createVNode(_component_v_icon, { icon: "next" })
        ], 2))
      : _createCommentVNode("", true),
    ($setup.phone_error !== '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createTextVNode(_toDisplayString($setup.strings.core["Number"]) + " " + _toDisplayString($setup.phone_error) + " " + _toDisplayString($setup.strings.core["NotValid"]) + " ", 1),
          _createElementVNode("a", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.hide_error()))
          }, [
            _createVNode(_component_v_icon, { icon: "close" })
          ])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}