import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "pricing-plans col-xl-8 xyz-in",
  xyz: "fade down-3 duration-6 delay-1"
}
const _hoisted_2 = { class: "head d-flex align-c justify-between mb-4" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "tag bg-light" }
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = { class: "text-primary" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "mt-1 mb-2" }
const _hoisted_11 = { class: "list icons mb-2" }
const _hoisted_12 = { class: "col-md-6" }
const _hoisted_13 = { class: "card bg-primary text-white" }
const _hoisted_14 = { class: "text-primary bg-white tag" }
const _hoisted_15 = { class: "mt-2" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "mt-1 mb-2" }
const _hoisted_18 = { class: "list icons mb-2" }
const _hoisted_19 = { class: "text-white" }
const _hoisted_20 = { class: "text-white" }
const _hoisted_21 = { class: "text-white" }
const _hoisted_22 = { class: "text-white" }
const _hoisted_23 = { class: "text-white" }
const _hoisted_24 = { class: "text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString($setup.strings.core['Pricing']), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("h6", _hoisted_6, _toDisplayString($setup.strings.pricing[5]), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h3", _hoisted_8, _toDisplayString($setup.trial_price()), 1),
            _createElementVNode("p", _hoisted_9, _toDisplayString($setup.strings.pricing[6]), 1)
          ]),
          _createElementVNode("p", _hoisted_10, _toDisplayString($setup.set_price($setup.strings.pricing[7])), 1),
          _createElementVNode("ul", _hoisted_11, [
            _createElementVNode("li", null, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[15]), 1)
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[16]), 1)
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[17]), 1)
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[18]), 1)
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[19]), 1)
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[20]), 1)
            ])
          ]),
          _createVNode(_component_router_link, {
            to: "/sign-up",
            class: "btn block bg-light"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.strings.pricing[8].toLowerCase()), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", null, [
            _createElementVNode("h6", _hoisted_14, _toDisplayString($setup.strings.pricing[9]), 1)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("h3", null, _toDisplayString($setup.subscription_price()), 1),
            _createElementVNode("p", _hoisted_16, _toDisplayString($setup.strings.pricing[10]), 1)
          ]),
          _createElementVNode("p", _hoisted_17, _toDisplayString($setup.strings.pricing[11]), 1),
          _createElementVNode("ul", _hoisted_18, [
            _createElementVNode("li", _hoisted_19, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[15]), 1)
            ]),
            _createElementVNode("li", _hoisted_20, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[16]), 1)
            ]),
            _createElementVNode("li", _hoisted_21, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[17]), 1)
            ]),
            _createElementVNode("li", _hoisted_22, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[18]), 1)
            ]),
            _createElementVNode("li", _hoisted_23, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[19]), 1)
            ]),
            _createElementVNode("li", _hoisted_24, [
              _createVNode(_component_icon, { icon: "arrowRight" }),
              _createTextVNode(" " + _toDisplayString($setup.strings.pricing[20]), 1)
            ])
          ]),
          _createVNode(_component_router_link, {
            to: "/sign-up",
            class: "btn block bg-secondary sh"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.strings.pricing[12]), 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}