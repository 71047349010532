<template>
  <section class="features section">
    <div class="container">
      <div class="row">

        <!-- 1 -->
        <div class="col-md-4">
          <icon icon="phone"></icon>
          <h4 class="mb-1">{{ strings.home.main[3] }}</h4>
          <p>{{ strings.home.main[4] }}</p>
        </div>

        <!-- 2 -->
        <div class="col-md-4">
          <icon icon="signal"></icon>
          <h4 class="mb-1">{{ strings.home.main[5] }}</h4>
          <p>{{ strings.home.main[6] }}</p>
        </div>

        <!-- 3 -->
        <div class="col-md-4">
          <icon icon="devices"></icon>
          <h4 class="mb-1">{{ strings.home.main[7] }}</h4>
          <p>{{  strings.home.main[8] }}</p>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import Icon from '@/components/icons/icon'
import { strings } from '@/strings'

export default {
  name: 'Features',
  components: { Icon },
  setup () {
    return { strings }
  }
}
</script>
