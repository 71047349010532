
import { strings } from '@/strings'
import { trial_price } from '@/utils/checkout'

export default {
  name: 'PricingHeader',
  setup () {

    const sub_title = () => {
      return strings.pricing[2].replace('€0.50', trial_price())
    }

    return { strings, sub_title }
  }
}
