import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container pt-8 pb-6" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pricing_header = _resolveComponent("pricing-header")!
  const _component_pricing_plans = _resolveComponent("pricing-plans")!

  return (_openBlock(), _createElementBlock("div", {
    class: "pricing",
    key: _ctx.$route.fullPath
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_pricing_header),
        _createVNode(_component_pricing_plans)
      ])
    ])
  ]))
}