import { XHR } from '@/utils/xhr'

let user_geo: string|undefined

const get_geo = () => {
  user_geo =  get_cookie('country')
  return user_geo
}


function get_cookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  console.log(parts)
  if (parts.length === 2) return parts.pop()!.split(';').shift();
}

export { get_geo, user_geo }
