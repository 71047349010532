import {
  ConfirmPaymentData,
  FieldOption, FieldsOption,
  loadStripe,
  Stripe,
  StripeElementLocale, StripeElementsOptions, StripePaymentElementOptions
} from '@stripe/stripe-js'
import { config } from '@/conf'
import { XHR } from '@/utils/xhr'
import { localstore } from '@/utils/localstorage'
import { get_geo } from '@/utils/geo'


const trial_pricing_dev = {"czk":1900,"try":2900,"zar":2000,"sek":600,"chf":100,"www":99,"mxn":1900,"idr":1590000,"nzd":150,"myr":500,"pen":500,"clp":855,"sar":300,"gbp":90,"vnd":26000,"ron":300,"hkd":800,"twd":3200,"aed":299,"eur":50,"ils":300,"pln":500,"cad":75,"jpy":150,"krw":1500,"ars":90000,"thb":3700,"uah":3900,"egp":3300,"cop":520000,"aud":90,"mad":900,"php":6000,"sgd":75,"huf":20500,"inr":8500,"brl":600,"qar":400,"usd":99,"dkk":999,"nok":1299,"usd3":99}
const sub_pricing_dev = {"sar":7800,"idr":16050000,"huf":1099900,"czk":71000,"nzd":7000,"eur":3950,"gbp":3900,"jpy":2780,"krw":42000,"cop":9750000,"thb":70000,"qar":7800,"brl":16000,"ron":19000,"sek":32500,"ars":750000,"usd":4995,"mad":9900,"clp":18000,"twd":65000,"inr":22500,"myr":9400,"www":2995,"aud":6500,"vnd":245000,"chf":3800,"try":38500,"ils":7200,"pen":11500,"cad":4900,"php":54000,"uah":79000,"mxn":38000,"hkd":24500,"sgd":4200,"egp":62500,"zar":37400,"dkk":21500,"nok":45000,"pln":16900,"aed":15100,"usd3":499}


class Checkout {
  spk!: string
  trial_pricing!: {[key: string]: number}
  sub_pricing!: {[key: string]: number}
  stripe!: Stripe

  // checkout initializer
  constructor () {
    // set pre-defined pricing (for dev)
    this.trial_pricing = trial_pricing_dev
    this.sub_pricing  = sub_pricing_dev
    this.spk = 'pk_live_51MFeGFK2UMrYUtMH7j26OjeoY1K4JAAqZiJtlNjop6Ywek9h3RJQM7JV4mlRbB4sl7OphZVoiyxvxRJQgGZR9CkK00Mo5fZs0c'

    // remove euro if current currency is eu2
    /* const current_currency = localstore.get('currency')
    if (current_currency && current_currency === 'eur2') {
      delete currency_formats['eur']
    } else if (current_currency && current_currency === 'usd2') {
      delete currency_formats['usd']
      delete currency_formats['usd3']
    } else if (current_currency && current_currency === 'usd3') {
      delete currency_formats['usd']
      delete currency_formats['usd2']
    } else if (current_currency && current_currency === 'cad2') {
      delete currency_formats['cad']
    }else if (current_currency && current_currency === 'aud2') {
      delete currency_formats['aud']
    } */

    // init xhr
    /*const xhr = new XHR()
    // fetch stripe public key and pricing from server
    xhr.get('/stripe').then(r => {
      const resp_data = r.decode<StripeInfo>()
      if (!resp_data) { console.error('failed to get stripe info'); return }
      // set pub key
      // this.spk = resp_data!.spk
      // set updated pricing
      // this.trial_pricing = resp_data!.trial_pricing
      // this.sub_pricing = resp_data!.sub_pricing

      // remove euro if current currency is eu2
      const current_currency = localstore.get('currency')
      if (current_currency && current_currency === 'eur2') {
        delete currency_formats['eur']
      }

    }).catch(e => {
      console.error("failed to get stripe public key")
    })
     */

  }

  // initializes stripe module
  init () {
    // @ts-ignore
    loadStripe(this.spk).then((stripe: Stripe) => {
      this.stripe = stripe
    })
  }

  // launch checkout process
  launch (props: CheckoutConfig) {
    if (!check_card()) {
      return false
    }

    // init stripe elements
    const elements = this.stripe.elements({
      clientSecret: props.token,
      locale: (config.lang.iso === 'latam' ? 'es-419' : config.lang.iso) as StripeElementLocale
    })

    // get user country
    const country = get_geo()

    // configure stipe element
    const el_config: StripePaymentElementOptions = {
      terms: {card: 'never'}
    }

    // hide country field if cookie found
    if (country) {
      el_config.fields = {
        billingDetails: {
          address: {
            country: 'never'
          }
        }
      }
    }
    // create and mount stripe element
    const payment_el = elements.create('payment', el_config)
    payment_el.mount('#payment-element')

    // on payment element 'ready' event. element is rendered and mounted
    payment_el.on('ready', () => {
      // fire on_ready handler
      props.on_ready()

      // submit event listener
      const form = document.getElementById('payment-form')!
      form.addEventListener('submit', async (event) => {
        event.preventDefault()

        // fire on_busy handler
        props.on_busy(true)

        // confirm parameters
        const confirm_parameters: ConfirmPaymentData = {
          return_url: checkout_complete_url(props.uid)
        }

        // set user country if cookie found
        if (country) {
          confirm_parameters.payment_method_data = {
            billing_details: {
              address: {
                country: country
              }
            }
          }
        }

        // confirm setup intent
        const { error } = await this.stripe.confirmPayment({
          elements, confirmParams: confirm_parameters
        })

        // immediate error (invalid card data ect.)
        if (error) {
          // ******-----
          // console.log(error)
          // ------*****

          // card declined
          if (error.type == 'card_error') {
            // get card check num and increment
            let n = check_card_n(); n ++
            localstore.set('XNpD5J8X', n+'NpD5J8X')
          }
          // fire on_error handler
          props.on_error(error.message!)
          // fire on_busy handler
          props.on_busy(false)
        } else {
          // customer will be redirected to an intermediate site to authorize the payment,
          // then redirected to the `return_url`

          // fire on_busy handler
          props.on_busy(false)
        }
      })
    })

    // fire on_change event when user focus on element (to hide any previous errors)
    payment_el.on('focus', (event) => {
      props.on_change()
    })

    payment_el.on('change', (event) => {
      console.log('payment element change event', event)
    })

    return true
  }
}

// checks card status
const check_card = () => {
  const n = check_card_n()
  // error
  if (n === -1) {
    return false
  }
  // no data
  else if (n === 0) {
    return true
  }
  // limit reached
  else if (n > 1) {
    return false
  }
  return true
}
const check_card_n = () => {
  const data = localstore.get('XNpD5J8X')
  if (data) {
    const count = data.substring(0,1)
    const num = parseInt(count);
    if (!num) {
      console.error('check_card NaN')
      return -1
    }
    return num
  }
  return 0
}

// checkout configuration
interface CheckoutConfig {
  uid       : string
  token     : string
  on_ready  (): void
  on_busy   (_:boolean): void
  on_error  (_:string): void
  on_change (): void
}

// returns the url where the user is directed after completing checkout
const checkout_complete_url = (uid: string) : string => {
  // dev mode
  if (process.env.NODE_ENV === 'development') {
    console.log("checkout configured in dev mode [endpoint: http://127.0.0.1:4444]")
    return "http://127.0.0.1:4444/checkout?uid="+uid+"&lang="+config.lang.iso
  }

  // append user language iso2 code if the language is set in url (forced)
  if (config.lang.in_url) {
    // url with app domain name defined in config, user id and user language
    return "https://"+config.host+"/checkout?uid="+uid+"&lang="+config.lang.iso
  }

  // url with app domain name defined in config
  return "https://"+config.host+"/checkout?uid="+uid
}

// global checkout class
let checkout: Checkout
const InitCheckout = () => {
  checkout = new Checkout()
}

// stripe pub key and pricing
interface StripeInfo {
  spk           : string
  trial_pricing : {[key: string]: number}
  sub_pricing   : {[key: string]: number}
}

/*
  "brl": 10600,
  "pen": 7900,
  "ars": 390000,
  "clp": 16200,
  "egp": 62500,
  "try": 38500,
  "cop": 9750000,
  "mxn": 38000,
 */

// currency symbols
const currency_formats: {[key: string]: string} = {
  "eur": "€{n}",
  // "eur2": "€{n}",
  "gbp": "£{n}",
  "krw": "₩{n}",
  "sar": "{n} ر.س",
  "aed": "{n}د.إ",
  "dkk": "{n} kr. DKK",
  "czk": "{n} Kč",
  "sek": "{n} kr",
  "huf": "{n} Ft",
  "jpy": "¥{n}",
  "usd": "${n}",
  "www": "${n}",
  "usd2": "${n}",
  "usd3": "${n}",
  "aud": "${n}",
  // "aud2": "${n}",
  "sgd": "S${n}",
  "ron": "{n} lei",
  "cad": "C${n}",
  // "cad2": "${n}",
  "pln": "{n}zł",
  "nok": "{n} kr. NOK",
  "nzd": "${n}",
  "chf": "{n} CHF",
  "ils": "₪{n}",
  "qar": "QR {n}",
  "brl": "R${n}",
  "pen": "S/{n}",
  "ars": "$ARS {n}",
  "clp": "$CLP {n}",
  "egp": "£ {n}",
  "try": "₺{n}",
  "cop": "$COP {n}",
  "mxn": "${n}MXN",
  "uah": "₴{n}",
  "zar": "R{n}",
  "thb": "฿{n}",
  "myr": "RM{n}",
  "inr": "₹{n}",
  "twd": "元 {n}",
  "hkd": "${n}",
  "vnd": '₫{n}',
  "idr": 'Rp{n}',
  "php": '₱{n}',
  "mad": '{n}د.م.'
}

const no_cent = ['jpy', 'clp', 'krw', 'vnd']
const no_fraction = [
  'inr', 'myr', 'krw', 'jpy', 'try', 'pln', 'hkd', 'twd', 'czk', 'sek', 'chf',
  'sar', 'huf', 'ron', 'ils', 'brl', 'pen', 'ars', 'clp', 'egp', 'cop', 'mxn', 'uah', 'zar', 'thb', 'vnd', 'idr', 'php', 'mad'
]

const trial_price = () => {
  // get currency from session storage
  const currency = localstore.get('currency') || 'eur'
  // get amount int
  let amount = checkout.trial_pricing[currency]
  if (!no_cent.includes(currency)) {
    amount = amount/100
  }

  let price = ''

  if (no_fraction.includes(currency)) {
    price = currency_formats[currency].replace('{n}', amount.toFixed(0))
  } else {
    price = currency_formats[currency].replace('{n}', amount.toFixed(2))
  }

  if (currency === 'nok' || currency === 'dkk' || currency === 'vnd') {
    price = price.replace('.', ',')
  }

  return price
}
const subscription_price = () => {
  // get currency from session storage
  const currency = localstore.get('currency') || 'eur'
  // get amount int
  let amount = checkout.sub_pricing[currency]
  if (!no_cent.includes(currency)) {
    amount = amount/100
  }

  let price = ''

  if (no_fraction.includes(currency)) {
    price = currency_formats[currency].replace('{n}', amount.toFixed(0))
  } else {
    price = currency_formats[currency].replace('{n}', amount.toFixed(2))
  }

  if (currency === 'nok' || currency === 'dkk') {
    price = price.replace('.', ',')
  }

  return price
}

export { checkout, CheckoutConfig, InitCheckout, trial_price, subscription_price, currency_formats }
