const languages: { [key: string]: string }  = {
  cs: "Česky",
  da: "Dansk",
  de: "Deutsch",
  el: "Ελληνικά",
  en: "English",
  es: "Español",
  latam: "Español (LATAM)",
  fi: "Suomalainen",
  fr: "Français",
  hu: "Magyar",
  id: "Bahasa Indonesia",
  it: "Italiano",
  ja: "日本語",
  ko: "한국어",
  ms: "Melayu",
  nl: "Nederlands",
  no: "Norsk",
  pl: "Polski",
  pt: "Português",
  ro: "Românesc",
  ru: "русский",
  sk: "Slovenčina",
  sl: "Slovenščina",
  sv: "Svenska",
  th: "ภาษาไทย",
  tr: "Türkçe",
  uk: "українська",
  vi: "Tiếng Việt",
  zh: "普通话"
};

const country_iso2: { [key: string]: string }  = {
  cs: 'cz',
  da: 'dk',
  de: 'de',
  en: 'gb',
  es: 'es',
  latam: 'mx',
  el: 'gr',
  fr: 'fr',
  hu: 'hu',
  id: 'id',
  ko: 'kr',
  it: 'it',
  nl: 'nl',
  no: 'no',
  fi: 'fi',
  pl: 'pl',
  pt: 'pt',
  ro: 'ro',
  sl: 'sl',
  sv: 'sv',
  sk: 'sk',
  th: 'th',
  ru: 'ru',
  ms: 'my',
  tr: 'tr',
  zh: 'tw',
  ja: 'jp',
  vi: 'vn',
  uk: 'ua'
}

interface Language {
  iso    : string
  in_url : boolean
  country : string
}

// check if uri contains a language iso2 code
const uri_lang = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1')

// gets current language data
const init_language = (default_lang: string): Language => {
  // language data
  let language: Language = {
    iso     : default_lang,
    in_url  : false,
    country : default_lang
  }

  // attempt to get locale from url
  if (uri_lang !== undefined && uri_lang !== '/' && languages[uri_lang] !== undefined) {
    language.iso = uri_lang
    language.in_url = true
    language.country = uri_lang
  }

  language.country = country_iso2[language.country]

  // console.log(language, uri_lang, languages[uri_lang])

  return language
}

export { init_language, Language, languages, country_iso2 }
